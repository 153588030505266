/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f4f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(232, 238, 245);
    border-radius: 10px;
}