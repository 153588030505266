@import "@shared/styles/Colors.scss";

.header {
    display: flex;
    padding: 0;
    border-bottom: 1px solid #d9d9d9;
    &__title-container {
        min-width: 150px;
        margin-left: 20px;
        height: 100%;
        line-height: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation: fadeIn ease 1s;

        &__title {
            font-size: clamp(17px, 3vw, 26px);
            font-weight: 600;
        }

        &__subtitle {
            color: $secondary-text;
        }
    }

    background: #fff;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.ant-avatar-string {
    font-size: 30px;
}
