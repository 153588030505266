@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");

.terminal {
    background: #d9d9d9;
    padding: 16px 4px 4px 4px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 200;
    height: fit-content;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;

        background: #001529;
        padding: 16px;
        font-family: "Comfortaa", sans-serif;
        line-height: 24px;
        color: #d9d9d9;
        border-radius: 4px;
        height: fit-content;
        min-height: 300px;
        height: calc(100vh - 120px);
        overflow-y: scroll;
        scrollbar-width: none;
    }
}

.text {
    text-wrap: wrap;
    white-space: pre-line;
}
