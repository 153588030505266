.files-list {
    margin-left: 0;
    padding-left: 0;
    max-height: 150px;
    height: fit-content;
    overflow-y: auto;
    scrollbar-width: thin;
    width: fit-content;

    &__item {
        list-style-type: none;
        margin-bottom: 2px;
        position: relative;
        width: 184px;
    }
}
