.form-item {
    margin-bottom: 15px;
    width: 100%;

    &__label {
        font-size: 14px;
        margin-bottom: 1px;
        font-weight: 600;
    }

    &__input {
    }

    &__error {
        position: absolute;
        color: red;
        font-size: 12px;
        margin-top: -3px;
    }
}
