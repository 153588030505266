.table-wrapper {
    box-sizing: border-box;
    overflow-y: scroll;
    width: 100%;
    height: calc(100vh - 220px);
}

.table-wrapper-inner {
    width: fit-content;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar:horizontal {
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #f4f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgb(196, 196, 196);
}